import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextField from '@mui/material/TextField';

const dialogPaperStyle = {
  borderRadius: '10px',
  padding: '1rem', // Adjust the padding as needed
  maxWidth: '500px', // Optional: Set a maximum width for the dialog
};

// Array of Nigerian banks
const nigerianBanks = [
  "Access Bank",
  "First Bank of Nigeria",
  "Zenith Bank",
  "Guaranty Trust Bank (GTBank)",
  "United Bank for Africa (UBA)",
  "Union Bank of Nigeria",
  "Fidelity Bank",
  "Stanbic IBTC Bank",
  "Ecobank Nigeria",
  "Polaris Bank",
  "Sterling Bank",
  "Wema Bank",
  "Heritage Bank",
  "Keystone Bank",
  "Standard Chartered Bank",
  "CitiBank Nigeria",
  "Unity Bank",
];

export default function AddAccountDialog({ isOpen, handleClose }) {
  const [bankName, setBankName] = useState(0);
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const handleSubmit = () => {
    console.log('Bank Name:', bankName);
    console.log('Account Name:', accountName);
    console.log('Account Number:', accountNumber);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: dialogPaperStyle,
      }}
    >
      <DialogTitle>Add Bank Account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Select
            fullWidth
            placeholder="* Bank Name"
            variant="outlined"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            margin="normal"
            IconComponent={() => <ArrowDropDownIcon />}
          >
            <MenuItem value="0">Select Bank</MenuItem>
            {nigerianBanks.map((bank) => (
              <MenuItem key={bank} value={bank}>
                {bank}
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            placeholder="* Account Name"
            variant="outlined"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            placeholder="* Account Number"
            variant="outlined"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            margin="normal"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{
            flex: 1,
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            flex: 1,
            backgroundColor: 'lightblue',
            color: 'white',
            '&:hover': {
              backgroundColor: 'blue',
            },
          }}
        >
          Add Account
        </Button>
      </DialogActions>
    </Dialog>
  );
}
