import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; // Add this import
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AlertDialog from "../popup";
import axios from "axios";

function DynamicProprietorFields({
  proprietors,
  onAddProprietor,
  onRemoveProprietor,
  onUpdateProprietor,
}) {
  return (
    <div>
      {proprietors.map((proprietor, index) => (
        <Grid container spacing={2} key={index} style={{ marginTop: "2rem" }}>
          <Grid item>
            <h5>Add Proprietor {index + 1}</h5>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h4 style={{ fontSize: "14px" }}>* Valid ID</h4>
            <TextField
              fullWidth
              placeholder="Valid ID"
              variant="outlined"
              margin="normal"
              value={proprietor.validId}
              onChange={(e) =>
                onUpdateProprietor(index, "validId", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* First Name</h4>
            <TextField
              fullWidth
              placeholder="First Name"
              variant="outlined"
              margin="normal"
              value={proprietor.firstName}
              onChange={(e) =>
                onUpdateProprietor(index, "firstName", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* Middle Name</h4>
            <TextField
              fullWidth
              placeholder="Middle Name"
              variant="outlined"
              margin="normal"
              value={proprietor.middleName}
              onChange={(e) =>
                onUpdateProprietor(index, "middleName", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* Last Name</h4>
            <TextField
              fullWidth
              placeholder="Last Name"
              variant="outlined"
              margin="normal"
              value={proprietor.lastName}
              onChange={(e) =>
                onUpdateProprietor(index, "lastName", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* Residential Address</h4>
            <TextField
              fullWidth
              placeholder="Residential Address"
              variant="outlined"
              margin="normal"
              value={proprietor.address}
              onChange={(e) =>
                onUpdateProprietor(index, "address", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* BVN</h4>
            <TextField
              fullWidth
              placeholder="BVN"
              variant="outlined"
              margin="normal"
              value={proprietor.bvn}
              onChange={(e) => onUpdateProprietor(index, "bvn", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* Date of birth</h4>
            <TextField
              fullWidth
              placeholder="DOB"
              variant="outlined"
              margin="normal"
              value={proprietor.dob}
              onChange={(e) => onUpdateProprietor(index, "dob", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* Gender</h4>
            <TextField
              fullWidth
              placeholder="Gender"
              variant="outlined"
              margin="normal"
              value={proprietor.gender}
              onChange={(e) =>
                onUpdateProprietor(index, "gender", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* Phone Number</h4>
            <TextField
              fullWidth
              placeholder="Phone Number"
              variant="outlined"
              margin="normal"
              value={proprietor.phoneNumber}
              onChange={(e) =>
                onUpdateProprietor(index, "phoneNumber", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* Valid ID Number</h4>
            <TextField
              fullWidth
              placeholder="Valid ID Number"
              variant="outlined"
              margin="normal"
              value={proprietor.validIdNum}
              onChange={(e) =>
                onUpdateProprietor(index, "validIdNum", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h4 style={{ fontSize: "14px" }}>* Valid ID URL</h4>
            <TextField
              fullWidth
              placeholder="Valid ID URL"
              variant="outlined"
              margin="normal"
              value={proprietor.validIdUrl}
              onChange={(e) =>
                onUpdateProprietor(index, "validIdUrl", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CloudUploadIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* Add other fields in a similar manner */}

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                marginLeft: "8px",
              }}
            >
              {index === 0 ? null : (
                <button
                  type="button"
                  onClick={() => onRemoveProprietor(index)}
                  style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                >
                  <DeleteIcon style={{ cursor: "pointer" }} />
                </button>
              )}
              <button
                type="button"
                onClick={() => onAddProprietor(index)}
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                <AddIcon style={{ cursor: "pointer" }} />
              </button>
            </div>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

function NGOForm(props) {
  const [cacDocuments, setCacDocuments] = useState("");
  //const [trusteesInfo, setTrusteesInfo] = useState([""]); // Initialize with one empty trustee field
  //const [corporateBankAccount, setCorporateBankAccount] = useState('');
  //const [businessAddress, setBusinessAddress] = useState('');
  const [constitution, setConstitution] = useState("");
  const [scumlCertificate, setScumlCertificate] = useState("");
  //const [bvn, setBvn] = useState('');
  const [proprietors, setProprietors] = useState([
    {
      validId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      address: "",
      bvn: "",
      dob: "",
      gender: "",
      phoneNumber: "",
      validIdNum: "",
      validIdUrl: "",
    },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Function to make the API call
  const postKycData = async (requestData) => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        "/api/merchant/add-directors",
        requestData,
        { headers }
      );
      // Handle the response as needed

      if (response.status === 200) {
        return response.status;
      }
      console.log("API Response:", response.data);
    } catch (error) {
      // Handle errors
      console.error("API Error:", error);
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(false);
      }, 5000);

      // Set isOpen to false after 8 seconds
      setTimeout(() => {
        setIsOpen(false);
      }, 8700);
      throw error; // You can throw the error if needed
    }
  };

  const handleFileUpload = async (file, type) => {
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("file-type", type);
    formData.append("file", file);

    try {
      const response = await axios.post("/api/merchant/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.status;
      }
    } catch (error) {
      console.error("Error:", error);
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(false);
      }, 5000);

      // Set isOpen to false after 8 seconds
      setTimeout(() => {
        setIsOpen(false);
      }, 8700);
    }
  };

  // Function to submit KYC data for each proprietor
  const submitKyc = async (event) => {
    event.preventDefault();
    setIsOpen(true);
    setIsLoading(true);

    try {
      const uploadPromises = [];
      console.log(proprietors);
      // Loop through each proprietor and make the API call
      for (let index = 0; index < proprietors.length; index++) {
        const proprietorData = proprietors[index];

        // Modify the data as needed before making the API call
        const requestData = {
          address: proprietorData.address,
          bvn: proprietorData.bvn,
          dob: proprietorData.dob,
          firstName: proprietorData.firstName,
          gender: proprietorData.gender,
          lastName: proprietorData.lastName,
          middleName: proprietorData.middleName,
          phoneNumber: proprietorData.phoneNumber,
          validId: proprietorData.validId,
          validIdNum: proprietorData.validIdNum,
          validIdUrl: proprietorData.validIdUrl,
        };

        // Make the API call for each proprietor

        uploadPromises.push(postKycData(requestData));

        //await postKycData(corporateBankAccountFile);
        //await postKycData(businessAddressFile);
      }

      // Add file upload promise to the array
      uploadPromises.push(handleFileUpload(cacDocuments, "CAC"));
      uploadPromises.push(handleFileUpload(scumlCertificate, "SCULM"));
      uploadPromises.push(handleFileUpload(constitution, "CONSTITUTION"));

      // Wait for all promises to complete
      const responses = await Promise.all(uploadPromises);

      // Check if all responses have status 200
      const allStatus200 = responses.every((status) => status === 200);

      if (allStatus200) {
        console.log("Successful Upload", responses);

        // Set isLoading to false after 5 seconds
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(true);
        }, 5000);

        // Set isOpen to false after 8 seconds
        setTimeout(() => {
          setIsOpen(false);
        }, 8000);
      } else {
        // Set isLoading to false after 5 seconds
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(false);
        }, 5000);

        // Set isOpen to false after 8 seconds
        setTimeout(() => {
          setIsOpen(false);
        }, 8700);
        // Handle the case where at least one response has a status other than 200
        console.error("Not all responses have status 200");
        // You can throw an error or handle it as appropriate for your application
      }
    } catch (error) {
      // Handle errors
      console.error("Submit KYC Error:", error);
      // Handle the error state as needed
    }
  };

  const handleFileChange = (event, setter) => {
    const file = event.target.files[0];
    setter(file);
  };

  const handleProprietorChange = (index, field, value) => {
    const updatedProprietors = [...proprietors];
    updatedProprietors[index][field] = value;
    setProprietors(updatedProprietors);
  };

  const handleAddProprietor = () => {
    const updatedProprietors = [
      ...proprietors,
      {
        validId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        address: "",
        bvn: "",
        dob: "",
        gender: "",
        phoneNumber: "",
        validIdNum: "",
        validIdUrl: "",
      },
    ];
    setProprietors(updatedProprietors);
  };

  const handleRemoveProprietor = (index) => {
    if (index === 0) return; // Prevent removal of the first field
    const updatedProprietors = [...proprietors];
    updatedProprietors.splice(index, 1);
    setProprietors(updatedProprietors);
  };

  return (
    <Grid container spacing={2}>
      {/* Full CAC Documents */}
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="cac-documents">
          Full CAC Documents
        </InputLabel>
        <Input
          id="cac-documents"
          type="file"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{ accept: "image/*, application/pdf" }}
          onChange={(e) => setCacDocuments(e.target.files[0])}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>

      {/* Constitution/Memorandum of Guidance */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="constitution">
          Constitution/Memorandum of Guidance
        </InputLabel>
        <Input
          id="constitution"
          type="file"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          value={constitution}
          onChange={(e) => setConstitution(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      {/* SCUML Certificate */}
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="scuml-certificate">
          SCUML Certificate
        </InputLabel>
        <Input
          id="scuml-certificate"
          type="file"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{ accept: "image/*, application/pdf" }}
          onChange={(e) => setScumlCertificate(e.target.files[0])}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      {/*
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="ngos-business-address">
          Operational Business Address
        </InputLabel>
          <div style={{ display:"flex", alignItems:"center", marginBottom: '16px', border: '1px solid #ced4da', width:"100%", padding: '10px', borderRadius: '8px', boxSizing: 'border-box',}}>
        
            <CloudUploadIcon style={{fontSize:"50px", color:"grey"}}/>
        
            <input
              id="business-address"
              type="text"
              value={businessAddress}
              placeholder="Operational Business Address"
              style={{
                width: '100%',
                marginLeft:"0.7rem",
                border: 'none',
                fontSize: '14px',
                outline: 'none',
              }}
              onChange={(event) => setBusinessAddress(event.target.value)}
            />
            
          </div>
      </Grid>
            */}
      {/* Trustees’ Names, IDs */}
      <Grid item xs={12}>
        <DynamicProprietorFields
          proprietors={proprietors}
          onAddProprietor={handleAddProprietor}
          onRemoveProprietor={handleRemoveProprietor}
          onUpdateProprietor={handleProprietorChange}
        />
      </Grid>
      {/* BVN */}
      {/*
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="bvn">
          BVN
        </InputLabel>
        <Input
          id="bvn"
          type="text"
          fullWidth
          placeholder='* BVN'
          disableUnderline
          variant="outlined"
          margin="normal"
          value={bvn}
          onChange={(e) => setBvn(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
        </Grid>
        */}
      {/* Corporate Bank Account */}
      {/*
      <Grid item xs={12}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="ngos-corporate-account">
          Corporate Bank Account details
        </InputLabel>
        <Input
          id="ngos-corporate-account"
          type="text"
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          value={corporateBankAccount}
          onChange={(e) => setCorporateBankAccount(e.target.value)}
        />
        </Grid>*/}
      {/* Operational Business Address */}

      <Grid item>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ color: "white", marginTop: "16px" }}
          onClick={submitKyc}
          disabled={
            cacDocuments === null ||
            constitution === null ||
            scumlCertificate === null ||
            !proprietors.every((p) =>
              Object.values(p).every((value) => value !== "")
            )
          }
        >
          Submit
        </Button>
      </Grid>
      <AlertDialog isOpen={isOpen} isLoading={isLoading} success={success} />
    </Grid>
  );
}

export default NGOForm;
