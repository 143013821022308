import React from 'react';
import { Navigate } from 'react-router-dom';

const RouteProtection = ({ component: Component }) => {
  const isAuthenticated = localStorage.getItem('token') !== null;
  const userRole = localStorage.getItem('role'); // Assuming 'userRole' is the key for the user's role in localStorage

  // Check if the user is authenticated and has the role of 'merchant'
  const isMerchant = isAuthenticated && userRole === 'Merchant';

  return isMerchant ? <Component /> : <Navigate to="/authentication/sign-in" replace />;
};

export default RouteProtection;
