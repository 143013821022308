import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const dummyBankAccountData = [
  {
    bankName: 'Access Bank',
    accountName: 'John Doe',
    accountNumber: '1234567890',
  },
  {
    bankName: 'Zenith Bank',
    accountName: 'Jane Smith',
    accountNumber: '9876543210',
  },
  {
    bankName: 'Guaranty Trust Bank (GTBank)',
    accountName: 'Alice Johnson',
    accountNumber: '5678901234',
  },
  // Add more dummy data as needed
];

export default function TeamTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="bank-account-table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Account Name</TableCell>
            <TableCell>Bank Name</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyBankAccountData.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.accountName}
              </TableCell>
              <TableCell>{row.bankName}</TableCell>
              <TableCell>{row.accountNumber}</TableCell>
              <TableCell 
                style={{color:"blue", textDecoration:"underline", cursor: "pointer"}}
                onClick={(e) => { 
                  e.stopPropagation()
                }} 
              > 
                Edit 
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
