import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';

import CoverLayout from "layouts/authentication/components/CoverLayout";
import SimpleBackdrop from "../backdrop";
import SimpleSnackbar from "../snackbar";

import emailLogo from '../../../assets/images/email.webp';
import logo from "../../../assets/images/fairsure_logo.png";

import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { column } from "stylis";



function ConfirmEmail() {
  const { token } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const confirmEmail = async () => {
      setLoading(true);

      try {
        const requestBody = {
          code: token,
        };

        const response = await axios.post("/api/email-confirm-link", requestBody);

        if (response.status === 200) {
          setLoading(false);
          setSuccess(true);
          setError(null);

          setTimeout(() => {
            navigate("/authenticate/sign-in");
          }, 5000);
        } else {
          setLoading(false);
          setError("Email confirmation failed. Please try again.");
          setSuccess(false);
        }
      } catch (error) { 
        setLoading(false);
        setError("An error occurred during email confirmation. Please try again.");
        setSuccess(false);
      }
    };

    if (token) {
      confirmEmail();
    }
  }, [token, navigate]);

  // Determine the description and icon based on the 'success' state
const description = success !== null ? (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <span>{success ? 'Your email has been confirmed' : 'Email confirmation failed, please try again!'}</span>
    {success ? (
      <IoIosCheckmarkCircle size={80} color="green" style={{ marginLeft: '8px' }} /> // Green check icon
    ) : (
      <IoIosCloseCircle size={80} color="red" style={{ marginLeft: '8px' }} /> // Red cancel icon
    )}
  </div>
) : (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <span>Initial message before confirmation attempt</span>
    {/* You can include any other elements or messages you want here */}
  </div>
);

  



  return (
    <CoverLayout
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" width={40} height={40} style={{ marginRight: "8px" }} />
          <span>Email Confirmation</span>
        </div>
      }
      description={description}
      image={emailLogo}
    >
      {loading && <SimpleBackdrop open={loading} handleClose={() => setLoading(false)} />}
      {error && <SimpleSnackbar variant="error" open={true} messages={error} handleClose={() => setError(null)} />}
      {success && <SimpleSnackbar variant="success" open={success} handleClose={() => setSuccess(false)} message={"Email Confirmation Successful"} />}
    </CoverLayout>
  );
}

export default ConfirmEmail;
