import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";

function DeveloperTools() {
  const [publicKey, setPublicKey] = useState("XQYWTWWWLKCNSHSBXBSHSYEODMD");
  const [secretKey, setSecretKey] = useState("XXAKDKKSJETWODMDEJDHDDNKEHEKD");
  const [isPublicKeyCopied, setIsPublicKeyCopied] = useState(false);
  const [isSecretKeyCopied, setIsSecretKeyCopied] = useState(false);

  const handleCopyPublicKey = () => {
    navigator.clipboard.writeText(publicKey);
    setIsPublicKeyCopied(true);
    setTimeout(() => {
      setIsPublicKeyCopied(false);
    }, 5000); // 5 seconds
  };

  const handleCopySecretKey = () => {
    navigator.clipboard.writeText(secretKey);
    setIsSecretKeyCopied(true);
    setTimeout(() => {
      setIsSecretKeyCopied(false);
    }, 5000); // 5 seconds
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Typography variant="h4" style={{ marginTop: "1.8rem", marginBottom: "3rem" }} gutterBottom>
        Developer Settings
      </Typography>

      <Card>
        <CardContent>
          <div style={{ padding: "16px" }}>
            <Typography variant="h6" style={{ marginBottom: "1.5rem" }}>
              API/SDK Integrations
            </Typography>
            <Grid container spacing={3} style={{ display: "flex", flexDirection: "column" }}>
              <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                <InputLabel style={{ fontSize: "13px" }} htmlFor="client-id">
                  Client ID
                </InputLabel>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    fullWidth
                    id="client-id"
                    variant="outlined"
                    value={publicKey}
                    onChange={(e) => setPublicKey(e.target.value)}
                  />
                  <Button
                    onClick={handleCopyPublicKey}
                    startIcon={<FileCopyIcon />}
                    size="small"
                    disabled={isPublicKeyCopied} // Disable the button when copied
                    style={{ marginLeft: "8px" }} // Add margin to separate the button
                  >
                    {isPublicKeyCopied ? "Copied" : "Copy"}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                <InputLabel style={{ fontSize: "13px" }} htmlFor="secret-key">
                  API Secret Key
                </InputLabel>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    style={{width:"100%"}}
                    id="secret-key"
                    variant="outlined"
                    value={secretKey}
                    
                    onChange={(e) => setSecretKey(e.target.value)}
                  />
                  <Button
                    onClick={handleCopySecretKey}
                    startIcon={<FileCopyIcon />}
                    size="small"
                    disabled={isSecretKeyCopied} // Disable the button when copied
                    style={{ marginLeft: "8px" }} // Add margin to separate the button
                  >
                    {isSecretKeyCopied ? "Copied" : "Copy"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
      <div style={{ height: "10rem" }} />
      <Footer />
    </DashboardLayout>
  );
}

export default DeveloperTools;
