import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftBox from "components/SoftBox";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { LoadingButton } from '@mui/lab';
import LinearProgress from '@mui/material/LinearProgress';



export default function AlertDialog({ isOpen, handleClose, isLoading, success }) {
  

  
  

  

  return (
    <div>
      <Dialog
        open={isOpen}
        PaperProps={{
          style: {
            borderRadius: '10px',
            padding: '1rem', // Adjust the padding as needed
            width: '100%', // Make the dialog take the full width
          },
        }}
      >
        <DialogTitle>{""}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress disableShrink />
              Please Wait...
            </Box>
          ) : success ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={faCircleCheck} style={{ color: 'green', fontSize: '65px' }} />
              <p style={{fontSize:"15px"}}>Document Uploaded Successfully.</p>
              <p style={{fontSize:"15px"}}>Currently Under Review.</p>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '65px' }} />
              <p style={{fontSize:"15px"}}>Document Upload Failed.</p>
              <p style={{fontSize:"15px"}}>Please Try Again.</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
         
        </DialogActions>
      </Dialog>
    </div>
  );
}
