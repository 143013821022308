/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import React, { useState } from 'react';




function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        
      </SoftBox>
    </SoftBox>
  );
}

function Function({ email, }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {email}
      </SoftTypography>
      
    </SoftBox>
  );
}

const authorsTableData = {
  columns: [
    { name: "name", align: "left" },
    { name: "email", align: "left" },
    { name: "amount", align: "left" },
    { name: "reference", align: "left" },
    { name: "date", align: "left" },
    { name: "channel", align: "left" },
    { name: "status", align: "left" },
    { name: "action", align: "center" },
    
  ],

  rows: [
    {
      name: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
      email: <Function email="example@gmail.com" />,
      amount: <Function email="₦2,000" />,
      reference: <Function email="3388282999392920" />,
      
      
      date: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          23/04/18
        </SoftTypography>
      ),
      channel: <Function email="Transfer" />,
      status: (
        <SoftBadge variant="gradient" badgeContent="Pending" color="warning" size="xs" container />
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
          
          
        >
          Edit
        </SoftTypography>
      ),
    },
    
  ],
};

export default authorsTableData;
