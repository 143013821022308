import { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import typography from "assets/theme/base/typography";
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import PublicIcon from "@mui/icons-material/Public";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import Alert from '@mui/material/Alert';

import SimpleBackdrop from './backdrop';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const navigate = useNavigate(); 

  // Define state variables for the data properties
  const [balance, setBalance] = useState(0);
  const [successfulCount, setSuccessfulCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [pendingSettlement, setPendingSettlement] = useState(0);
  const [loading, setIsLoading] = useState(false);
  const [error, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsError(false);
        setIsLoading(true);

        const token = localStorage.getItem('token');
        const merchantId = localStorage.getItem('userId');
        const apiUrl = `/api/reports/analytics?merchantId=${merchantId}`;

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            // You can add other headers as needed
          },
        });

        const responseData = response.data.data;
        setBalance(responseData.balance);
        setSuccessfulCount(responseData.successfulCount);
        setFailedCount(responseData.failedCount);
        setPendingSettlement(responseData.pendingSettlement);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        // Check if the error response status is 401
        if (error.response && error.response.status === 401) {
          // Clear local storage
          localStorage.clear();
          
          // Navigate to the login route
          navigate('/authentication/sign-in');
        } else {
          setIsError(true);

          setTimeout(() => {
            setIsError(false);
          }, 5000);

          console.error('API Error:', error);
        }
      }
    };

    fetchData();
  }, []); 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {error && ( 
          <Alert variant="outlined" severity="error">
            Failed to fetch data, check network connection!
          </Alert>
        )}
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Available Balance" }}
                count={`₦${balance}`}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "info", component: "account_balance" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Successful" }}
                count={successfulCount}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "info", component: <CheckCircleIcon /> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Failed" }}
                count={failedCount}
                percentage={{ color: "error", text: "" }}
                icon={{ color: "info", component: <CancelIcon /> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Pending Settlements" }}
                count={pendingSettlement}
                percentage={{ color: "success", text: "" }}
                icon={{
                  color: "info",
                  component: <QueryBuilderIcon />,
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            
            <Grid item xs={12} lg={12}>
              <GradientLineChart
                title="Sales Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                height="20.25rem"
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SimpleBackdrop open={loading} />
        
        
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
