import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';

import CoverLayout from "layouts/authentication/components/CoverLayout";
import SimpleBackdrop from "../backdrop";
import SimpleSnackbar from "../snackbar";

import emailLogo from '../../../assets/images/email.webp';
import logo from "../../../assets/images/fairsure_logo.png";

import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { column } from "stylis";
import { Box, Typography } from "@mui/material";
import { Link } from 'react-router-dom';




function EmailSentScreen() {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  

  // Determine the description and icon based on the 'success' state
const description = (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <span>A confirmation email has been sent to the email address you provided during sign up.
      Please check your inbox and follow the instructions to confirm your email.</span>
    <IoIosCheckmarkCircle size={120} color="green" style={{ marginLeft: '8px' }} /> 
    <Box display="flex" flexDirection="row" > 
      <Typography style={{marginRight:'5px'}} variant="p">Back to</Typography><Link to="/authentication/sign-in">sign in</Link>
    </Box>
  </div>
);

  



  return (
    <CoverLayout
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" width={40} height={40} style={{ marginRight: "8px" }} />
          <span>Email Confirmation</span>
        </div>
      }
      description={description}
      image={emailLogo}
    >
      {loading && <SimpleBackdrop open={loading} handleClose={() => setLoading(false)} />}
      {error && <SimpleSnackbar variant="error" open={true} messages={error} handleClose={() => setError(null)} />}
      {success && <SimpleSnackbar variant="success" open={success} handleClose={() => setSuccess(false)} message={"Email Confirmation Successful"} />}
    </CoverLayout>
  );
}

export default EmailSentScreen;
