import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parse, isAfter, isBefore, isEqual } from "date-fns"; // Import date-fns functions for date manipulation

// @mui material components
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useMediaQuery, useTheme } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import "./action-menu.js";
import DynamicTable from "./table.js";
import "./date-picker.css";

import Axios from "axios"; // Import Axios library
import SimpleBackdrop from "./backdrop.js";


import CsvDownloader from 'react-csv-downloader';







function Tables() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;

  // State to store the selected date
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  // State to store the fetched transactions
  const [transactions, setTransactions] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [error, setIsError] = useState(false);

  // State to store the filtered transactions
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const [checked, setChecked] = useState(false);
  const [dateChecked, setDateChecked] = useState(false);

  // State to store the search text
  const [searchText, setSearchText] = useState("");

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const buttonSize = isXSmallScreen
    ? "small"
    : isSmallScreen
    ? "small"
    : isMediumScreen
    ? "small"
    : "xlarge";

  const handleClose = () => {
    setIsLoading(false);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleFilterOptionClick = (filterOption) => {
    handleCloseMenu();
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const [selectedValue, setSelectedValue] = useState(0);

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);

    // Map the numeric values to string status values
    let statusString = "";
    switch (event.target.value) {
      case "1":
        statusString = "SUCCESS";
        break;
      case "2":
        statusString = "PENDING";
        break;
      case "3":
        statusString = "AUTHORIZED";
        break;
      case "4":
        statusString = "FAILED";
        break;
      case "5":
        statusString = "REFUNDED";
        break;
      default:
        statusString = "";
    }

    setSelectedStatus(statusString);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDateCheckboxChange = (event) => {
    setDateChecked(event.target.checked);
  };

  // Define the API function
  const fetchTransactionsFromAPI = async () => {
    try {
      setIsError(false);
      setIsLoading(true);

      // Fetch the token from local storage
      const storedToken = localStorage.getItem("token");

      // Fetch the merchantId from local storage
      const merchantId = localStorage.getItem("userId");

      // Define the API URL with query parameters
      const apiUrl = `/api/reports/transactions?merchantId=${merchantId}&pageNo=1&pageSize=100`;

      // Fetch transactions from the API using Axios
      const response = await Axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      const data = response.data.data.content;
      return data;
    } catch (error) {
      setIsLoading(false);
      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);

      console.error("Error fetching transactions:", error);
      return [];
    }
  };

  useEffect(() => {
    // Fetch transactions when the component mounts
    fetchTransactionsFromAPI().then((data) => {
      setTransactions(data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    // Filter transactions based on status, date, and search text
    let filteredData = transactions;

    // Filter by search text
    if (searchText.trim() !== "") {
      filteredData = filteredData.filter((transaction) =>
        transaction.paysureRef.includes(searchText)
      );
    }

    // Filter by status
    if (checked && selectedStatus !== "") {
      filteredData = filteredData.filter(
        (transaction) => transaction.status === selectedStatus
      );
    }

    // Filter by date range
      // Filter by date range
    if (dateChecked) {
      // Create date objects with time components set to zero
      const startDateWithoutTime = startDate ? new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) : null;
      const endDateWithoutTime = endDate ? new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) : null;

      filteredData = filteredData.filter((transaction) => {
        const transactionDate = parse(
          transaction.transactionDate,
          "yyyy-MM-dd HH:mm:ss.SSSSSS",
          new Date()
        );

        // Check if transactionDate is a valid Date object
        if (isNaN(transactionDate.getTime())) {
          return false; // Skip this transaction if date is invalid
        }

        // Create a new Date object for transactionDate with time components set to zero
        const transactionDateWithoutTime = new Date(transactionDate.getFullYear(), transactionDate.getMonth(), transactionDate.getDate());


        // Check if the rest of the date filtering conditions are met
        const isAfterStartDate = startDateWithoutTime ? isAfter(transactionDateWithoutTime, startDateWithoutTime) : true;
        const isEqualStartDate = startDateWithoutTime ? isEqual(transactionDateWithoutTime, startDateWithoutTime) : true;
        const isBeforeEndDate = endDateWithoutTime ? isBefore(transactionDateWithoutTime, endDateWithoutTime) : true;
        const isEqualEndDate = endDateWithoutTime ? isEqual(transactionDateWithoutTime, endDateWithoutTime) : true;

        

        // Check if the transactionDate meets the filtering conditions
        return (
          (isAfterStartDate || isEqualStartDate) &&
          (isBeforeEndDate || isEqualEndDate)
        );
      });
    }

  
    

    setFilteredTransactions(filteredData);
    
  }, [checked, selectedStatus, dateChecked, startDate, endDate, transactions, searchText]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {error && (
        <Alert variant="outlined" severity="error">
          Failed to fetch data, check network connection!
        </Alert>
      )}
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Merchant Transactions</SoftTypography>
              <TextField
                placeholder="Transaction Reference"
                variant="outlined"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        style={{ cursor: "pointer", color: "blue" }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch} // Add this line to handle search text input
              />
            </SoftBox>
            <div style={{paddingLeft:'1rem', paddingRight:'1rem', marginBottom: "2rem", display:'flex', justifyContent:'space-between', width:'100%'}}>
              <Button
                aria-controls="filter-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
                variant="outlined"
                size={buttonSize}
                sx={{
                  height: 30,
                  backgroundColor: "primary",
                  borderRadius: "8px",
                  color: "blue",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                Filter Options
              </Button>
              <CsvDownloader
                datas={filteredTransactions}
                filename={'fairsure_merchant_transactions'}
                extension=".csv"
              >
                <Button
                  variant="outlined"
                  style={{
                    flex: 1,
                    backgroundColor: 'white', // Use the lighter blue color
                    color: 'blue',
                    '&:hover': {
                      backgroundColor: 'darkblue',
                    },
                    fontSize: '12px', // Adjust the font size as needed
                  }}
                >
                  Export to CSV
                </Button>
              </CsvDownloader>
            </div>

            <SoftBox>
              <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    maxHeight: "400px",
                    width: "300px",
                  },
                }}
              >
                <MenuItem>
                  <div style={{ marginBottom: "4rem", width: "100%" }}>
                    <div style={{ marginBottom: "5px" }}>
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                        style={{color:'black', border:"1.7px solid black"}}
                      />
                      Filter by Status
                    </div>
                    <Select
                      value={selectedValue}
                      style={{ marginTop: 10, width: "100%" }}
                      onChange={handleValueChange}
                      disabled={!checked}
                    >
                      <MenuItem value="0">Select Status</MenuItem>
                      <MenuItem value="1">Successful</MenuItem>
                      <MenuItem value="2">Pending</MenuItem>
                      <MenuItem value="3">Authorized</MenuItem>
                      <MenuItem value="4">Failed</MenuItem>
                      <MenuItem value="5">Refund</MenuItem>
                    </Select>
                  </div>
                </MenuItem>

                <MenuItem>
                  <div style={{ marginBottom: "4rem", width: "100%" }}>
                    <div style={{ marginBottom: "10px" }}>
                      <Checkbox
                        checked={dateChecked}
                        onChange={handleDateCheckboxChange}
                        style={{color:'black', border:"1.7px solid black"}}
                      />
                      Filter by Date
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ marginRight: "4px" }}>
                        <h5>Start</h5>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="custom-date-picker"
                          disabled={!dateChecked}
                        />
                      </div>
                      <div style={{ marginLeft: "4px" }}>
                        <h5>End</h5>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="custom-date-picker"
                          disabled={!dateChecked}
                        />
                      </div>
                    </div>
                  </div>
                </MenuItem>
              </Menu>
            </SoftBox>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SimpleBackdrop open={loading} />
              <DynamicTable transactions={filteredTransactions} />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
