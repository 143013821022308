import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from "@mui/material/InputAdornment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Import the dropdown icon
import FileCopyIcon from '@mui/icons-material/FileCopy'; // Import the copy icon

const dialogPaperStyle = {
  borderRadius: '10px',
  padding: '1rem', // Adjust the padding as needed
  width: '100%', // Make the dialog take the full width
};

const labelStyle = {
  fontWeight: 'bold',
  marginRight: '1rem',
};

const inputStyle = {
  border: '1px solid #ced4da',
  borderRadius: '0.5rem',
  padding: '0.6rem 0.75rem',
  fontSize: '0.9rem',
  width: '100%',
  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
};

export default function EditBill({ isOpen, handleClose, rowData }) {
  const [billNameValue, setBillNameValue] = useState('');
  const [amountValue, setAmountValue] = useState('');
  const [isFixed, setIsFixed] = useState('');
  const [isPublicValue, setIsPublicValue] = useState('Private');
  const [billLink, setBillLink] = useState(''); // State variable for bill link
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const [isCopyButtonDisabled, setIsCopyButtonDisabled] = useState(false);

  useEffect(() => {
    // Set state variables with the rowData when the component mounts
    if (rowData) {
      setBillNameValue(rowData.billName || '');
      setAmountValue(rowData.amount || '');
      setIsFixed(rowData.amountType || '');
      setBillLink(rowData.billLink || '');
      // You can set the initial bill link here if it's available in rowData
    }
  }, [rowData, isOpen]); // Run this effect whenever rowData changes

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === 'isFixed') {
      setIsFixed(checked);
      if (!checked) {
        setAmountValue('');
      }
    } else if (name === 'billName') {
      setBillNameValue(value);
    } else if (name === 'amount') {
      setAmountValue(value);
    } else if (name === 'isPublic') {
      setIsPublicValue(value);
    }
  };

  // Function to handle copying the bill link to the clipboard
  const copyBillLink = () => {
    navigator.clipboard.writeText(billLink);
    // You can provide user feedback here (e.g., show a message that the link was copied)
    setCopyButtonText('Copied');
    setIsCopyButtonDisabled(true);

    // Reset the button text and enable it after 5 seconds
    setTimeout(() => {
      setCopyButtonText('Copy');
      setIsCopyButtonDisabled(false);
    }, 5000); // 5000 milliseconds (5 seconds)
  };

  const handleSave = () => {
    // Implement logic to save the edited data here
    // You can access the individual state variables
    handleClose(); // Close the dialog after saving
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: dialogPaperStyle,
      }}
    >
      <DialogTitle>Edit Bill</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Name of Bill:</span>
            <input
              type="text"
              name="billName"
              value={billNameValue}
              onChange={handleInputChange}
              style={inputStyle}
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Set Amount:</span>
            {isFixed && (
              <input
                type="text"
                name="amount"
                value={amountValue}
                onChange={handleInputChange}
                style={inputStyle}
              />
            )}
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Select if amount is fixed or not:</span>
            <FormControlLabel
              control={
                <Checkbox
                  name="isFixed"
                  checked={isFixed}
                  onChange={handleInputChange}
                  color="primary"
                />
              }
              label="Fixed"
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Set Bill to Public or Private:</span>
            <FormControl fullWidth>
              <Select
                name="isPublic"
                value={isPublicValue}
                onChange={handleInputChange}
                startAdornment={
                  <InputAdornment position="start">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                }
              >
                <MenuItem value="Public">Public</MenuItem>
                <MenuItem value="Private">Private</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: "13px" }}>Bill Link:</span>
            <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                name="billLink"
                value={billLink}
                onChange={(e) => setBillLink(e.target.value)}
                style={inputStyle}
              />
              <Button
                onClick={copyBillLink}
                color="primary"
                startIcon={<FileCopyIcon />}
                size="small"
                disabled={isCopyButtonDisabled}
              >
                {copyButtonText}
              </Button>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className="small-button"
          style={{
            flex: 1,
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: 'grey',
            },
            fontSize: '9px',
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleSave}
          className="small-button"
          style={{
            flex: 1,
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkgreen',
            },
            fontSize: '9px',
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
