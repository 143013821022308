import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; // Add this import
import Button from "@mui/material/Button";
import AlertDialog from '../popup';
import axios from 'axios';











function InstitutionForm(props) {
  const [proofOfInstitution, setProofOfInstitution] = useState(null);
  const [signatoryCFO, setSignatoryCFO] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);




  const submitKyc = async (event) => {
    event.preventDefault();
    setIsOpen(true);
    setIsLoading(true);

    try{  
      const uploadPromises = [];


    uploadPromises.push(handleFileUpload(proofOfInstitution, "PROOF_OF_INSTITUTE"));
    uploadPromises.push(handleFileUpload(signatoryCFO, "SIGNATORY_CFO"));

    // Wait for all promises to complete
    const responses = await Promise.all(uploadPromises);
    
    // Check if all responses have status 200
    const allStatus200 = responses.every((status) => status === 200);

    if (allStatus200) {
      console.log('Successful Upload', responses);
      
      // Set isLoading to false after 5 seconds
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(true);
      }, 5000);
    
      // Set isOpen to false after 8 seconds
      setTimeout(() => {
        setIsOpen(false);
      }, 8000);

    } else {
      // Set isLoading to false after 5 seconds
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(false);
      }, 5000);
    
      // Set isOpen to false after 8 seconds
      setTimeout(() => {
        setIsOpen(false);
      }, 8700);
      // Handle the case where at least one response has a status other than 200
      console.error('Not all responses have status 200');
      // You can throw an error or handle it as appropriate for your application
    }
    } catch (error) {
      // Handle errors
      console.error('Submit KYC Error:', error);
      // Handle the error state as needed
    }
    
  };


  const handleFileUpload = async (file, type) => {
    
    const token = localStorage.getItem('token');

    const formData = new FormData();
    formData.append("file-type", type);
    formData.append("file", file,);

    try {
      const response = await axios.post(
        "/api/merchant/upload",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}` ,
          },
        }
      );
      
      if(response.status === 200){ 
        return response.status;
      }
    } catch (error) {
      console.error('Error:', error);
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(false);
      }, 5000);
    
      // Set isOpen to false after 8 seconds
      setTimeout(() => {
        setIsOpen(false);
      }, 8700);
    }
  };

  




  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="proof-of-institution">
          Proof of Institution
        </InputLabel>
        <Input
          id="proof-of-institution"
          type="file" // Change the input type to 'file'
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{ accept: 'image/*, application/pdf' }} // Specify accepted file types
          onChange={(e) => setProofOfInstitution(e.target.files[0])} // Store the selected file in state
          startAdornment={ // Add the cloud upload icon
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InputLabel style={{ fontSize: "14.5px" }} htmlFor="signatory-cfo">
          Signatory of the chief financial officer
        </InputLabel>
        <Input
          id="signatory-cfo"
          type="file" // Change the input type to 'file'
          fullWidth
          disableUnderline
          variant="outlined"
          margin="normal"
          inputProps={{ accept: 'image/*, application/pdf' }} // Specify accepted file types
          onChange={(e) => setSignatoryCFO(e.target.files[0])} // Store the selected file in state
          startAdornment={ // Add the cloud upload icon
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ color: "white", marginTop: "16px" }}
          onClick={submitKyc}
          disabled={proofOfInstitution === null || signatoryCFO === null}
        >
          Submit
        </Button>
      </Grid>
      <AlertDialog isOpen={isOpen} isLoading={isLoading} success={success}/>
    </Grid>
  );
}

export default InstitutionForm;
